(window as any).env =
  process.env.environment === 'production'
    ? {
      apiVersion: '${API_VERSION}',
      environment: '${ENVIRONMENT}',

      academyPublicUrl: '${ACADEMY_PUBLIC_URL}',
      dataPrivacyPublicUrl: '${DATA_PRIVACY_PUBLIC_URL}',

      enablePendo: '${ENABLE_PENDO}',

      newRelicAccountId: "${NEW_RELIC_ACCOUNT_ID}",
      newRelicAgentId: "${NEW_RELIC_AGENT_ID}",
      newRelicApplicationId: "${NEW_RELIC_APPLICATION_ID}",
      newRelicLicenseKey: "${NEW_RELIC_LICENSE_KEY}",
      newRelicTrustKey: "${NEW_RELIC_TRUST_KEY}",

      googleUrl: '${GOOGLE_URL}',
      googleClientId: '${GOOGLE_CLIENT_ID}',
      googleScope: '${GOOGLE_SCOPE}',

      entraUrl: '${ENTRA_URL}',
      entraClientId: '${ENTRA_CLIENT_ID}',
      entraScope: '${ENTRA_SCOPE}',

      staticPublicUrl: '${STATIC_PUBLIC_URL}'
    }
    : {
      enablePendo: 'true',
      environment: process.env.proxyMode,
      academyPublicUrl: 'http://app.local.proliance360.com',
      dataPrivacyPublicUrl: 'http://app.local.proliance360.com',

      googleUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
      googleClientId: '672685295527-t004m4044913u4rfddbqpta17qda77ha.apps.googleusercontent.com',
      googleScope: 'openid profile email',

      entraUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      entraClientId: 'f00756f0-ba82-4420-9e1d-b57ffc93a7e8',
      entraScope: 'openid profile email'

      // staticPublicUrl: 'http://static.local.proliance360.com',
    }; // Can be used for testing purposes

export {};
